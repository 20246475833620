export const theme = {
  global: {
    focus: { border: { color: "transparent" } },
    colors: {
      brand: "#343464",
      "accent-1": "#9060EB",
      "accent-2": "#37edff",
      "accent-3": "#81FCED",
      "light-1": "#F8F8F8",
      "light-2": "#F2F2F2",
      "light-3": "#EDEDED",
      "light-4": "#DADADA",
      lightBackground: "#F8F9FA",
      white: "#FFFFFF",
      text: {
        light: "#444444",
        dark: "#f8f8f8",
      },
    },
    font: {
      family: "Muli",
      style: "sans-serif",
    },
  },
  accordion: { border: { color: "#ECECF3" } },
  anchor: {
    fontWeight: 400,
  },
  button: {
    border: { radius: "12px" },
  },

  heading: {
    extend: { letterSpacing: "-.03em" },
    weight: 600,
    level: {
      "2": {
        font: {
          family: "Muli",
          style: "sans-serif",
          weight: 900,
        },
      },
      "3": {
        font: {
          weight: 900,
        },
        medium: {
          size: "24px",
          height: "32px",
          maxWidth: "624px",
        },
      },
      "4": {
        font: {
          weight: 900,
        },
      },
    },
  },
}

export const themeExtension = {
  colors: {
    lightGreen: "linear-gradient(188.09deg, #0DFFB7 -207.02%, #0EF0B2 232.93%)",
    green: "#2EE7AF",
    lightBlue: "linear-gradient(280.85deg, #79EADF 7.89%, #61FFFA 95.07%)",
    orange: "linear-gradient(266.09deg, #FFC47F 3.13%, #EC008C 707.23%)",
    red: "#EC008C",
    backgrounds: {
      light: "#F1F4F8",
      dark: "#4F4F4F",
    },
  },
  gradients: {
    greenToLight: "linear-gradient(293.32deg, #62C3A6 7.89%, #00527E 95.07%)",
    greenToBright: "linear-gradient(216.9deg, #2EE7AF 1.56%, #00527E 110.83%)",
    greenDarkToBright:
      "linear-gradient(359.31deg, #62C3A6 -27.41%, #62C3A6 10.45%, #00527E 118.03%, #00527E 118.04%)",
    dark: "linear-gradient(to bottom, #2b5876 0%,#4e4376 100%);",
  },
  shadows: {
    soft: "0px 6px 24px rgba(0, 0, 0, 0.24)",
    graySoft: "0px 18px 28px rgba(90, 90, 90, 0.1)",
    softLightBlue: "0px 4px 6px rgba(27, 133, 189, 0.1)",
  },
}
