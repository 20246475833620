// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("./../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-faq-accordion-js": () => import("./../src/pages/faq/Accordion.js" /* webpackChunkName: "component---src-pages-faq-accordion-js" */),
  "component---src-pages-faq-contact-js": () => import("./../src/pages/faq/Contact.js" /* webpackChunkName: "component---src-pages-faq-contact-js" */),
  "component---src-pages-faq-faq-contact-js": () => import("./../src/pages/faq/FaqContact.js" /* webpackChunkName: "component---src-pages-faq-faq-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-pricing-contact-js": () => import("./../src/pages/pricing/Contact.js" /* webpackChunkName: "component---src-pages-pricing-contact-js" */),
  "component---src-pages-pricing-dynamic-pricing-js": () => import("./../src/pages/pricing/DynamicPricing.js" /* webpackChunkName: "component---src-pages-pricing-dynamic-pricing-js" */),
  "component---src-pages-pricing-pricing-contact-js": () => import("./../src/pages/pricing/PricingContact.js" /* webpackChunkName: "component---src-pages-pricing-pricing-contact-js" */),
  "component---src-pages-pricing-pricing-tabs-js": () => import("./../src/pages/pricing/PricingTabs.js" /* webpackChunkName: "component---src-pages-pricing-pricing-tabs-js" */),
  "component---src-pages-pricing-table-js": () => import("./../src/pages/pricing/Table.js" /* webpackChunkName: "component---src-pages-pricing-table-js" */),
  "component---src-pages-spoor-device-agnostic-js": () => import("./../src/pages/spoor/DeviceAgnostic.js" /* webpackChunkName: "component---src-pages-spoor-device-agnostic-js" */),
  "component---src-pages-spoor-landing-js": () => import("./../src/pages/spoor/Landing.js" /* webpackChunkName: "component---src-pages-spoor-landing-js" */),
  "component---src-pages-spoor-product-types-js": () => import("./../src/pages/spoor/ProductTypes.js" /* webpackChunkName: "component---src-pages-spoor-product-types-js" */),
  "component---src-pages-spoor-supply-chain-js": () => import("./../src/pages/spoor/SupplyChain.js" /* webpackChunkName: "component---src-pages-spoor-supply-chain-js" */),
  "component---src-pages-spoor-trip-management-js": () => import("./../src/pages/spoor/TripManagement.js" /* webpackChunkName: "component---src-pages-spoor-trip-management-js" */),
  "component---src-pages-spoor-want-to-know-more-js": () => import("./../src/pages/spoor/WantToKnowMore.js" /* webpackChunkName: "component---src-pages-spoor-want-to-know-more-js" */),
  "component---src-pages-faq-content-contact-form-md": () => import("./../src/pages/faq/content/contactForm.md" /* webpackChunkName: "component---src-pages-faq-content-contact-form-md" */),
  "component---src-pages-pricing-content-contact-form-md": () => import("./../src/pages/pricing/content/contactForm.md" /* webpackChunkName: "component---src-pages-pricing-content-contact-form-md" */),
  "component---src-pages-pricing-content-landing-md": () => import("./../src/pages/pricing/content/landing.md" /* webpackChunkName: "component---src-pages-pricing-content-landing-md" */),
  "component---src-pages-pricing-content-spoor-md": () => import("./../src/pages/pricing/content/spoor.md" /* webpackChunkName: "component---src-pages-pricing-content-spoor-md" */),
  "component---src-pages-spoor-content-devices-md": () => import("./../src/pages/spoor/content/devices.md" /* webpackChunkName: "component---src-pages-spoor-content-devices-md" */),
  "component---src-pages-spoor-content-landing-md": () => import("./../src/pages/spoor/content/landing.md" /* webpackChunkName: "component---src-pages-spoor-content-landing-md" */),
  "component---src-pages-spoor-content-product-types-md": () => import("./../src/pages/spoor/content/productTypes.md" /* webpackChunkName: "component---src-pages-spoor-content-product-types-md" */),
  "component---src-pages-spoor-content-supplychain-md": () => import("./../src/pages/spoor/content/supplychain.md" /* webpackChunkName: "component---src-pages-spoor-content-supplychain-md" */),
  "component---src-pages-faq-content-2019-12-23-16-faq-items-md": () => import("./../src/pages/faq/content/2019-12-23-16_faq-items.md" /* webpackChunkName: "component---src-pages-faq-content-2019-12-23-16-faq-items-md" */)
}

